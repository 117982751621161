import React from 'react';
import Card from '../components/Card';
import InputRangeSlider from '../components/Slider';
import './Mint1.css'
import { useState, useEffect } from 'react';
import MintButton from '../components/MintButton';
import ConnectButton from '../components/ConnectButton';
import { useParams } from 'react-router-dom';
import { checkSupply,checkBal,checkStatus,getCurrentWalletConnected } from "../utils/interact";

function Mint1() {
  
  const[free,setFree] = useState(false);
  const[wallet,setWallets] = useState();
  const[bal,setBal] = useState();
  const[error,setError] = useState(true);

  checkStatus().then((count) => {
    console.log(count);
    if(count==0){
      setFree(true);
    }
  })

  // getCurrentWalletConnected().then((msg)=>{
  //   setWallet(msg);
  // })



  useEffect(() => {
    const count = checkStatus().then((count) => {
      console.log(free);
      if(count==0){
        setFree(true);
      }
    })

    
  },[])
  
  const [total,setTotal] = useState();
  const [valid,setValid] = useState(true);
  const [totalDisplay,setTotalDisplay] = useState(false);
  
  const { id } = useParams();
 
  

  console.log(total);
  
  console.log(id);


  

  
  const [connect,setConnect] = useState(true);
  const [value,setValue]= useState(1);

  if(!connect){
    checkSupply().then((data) => {
      if(data.success!=false){
        setTotalDisplay(true)
        setTotal(data);
      }
    })
    // checkBal().then((data) => {
    //   if(data){
    //     console.log(data);
    //     setBal(data);
    //   }
    // })
  }

  return (
    <>
    <div className='test'>
      <div className='mobalert'>
      <p>Note: Use metamask browser on mobile device. </p>
      
      </div>
      {/* {!connect && <div className='cyber-tile bg-[#3030309b] mb-4'><div className='p-2 text-white'>wallet connected: {wallet}</div></div>} */}
        <div className='mintcard'>
      <p className='text-[#ff0000] text-[10px] mb-2'>Note: Mint only works if you have sufficient balance for gas.</p>
        <Card value={value}/>
        {/* {free ? <p className='text-white mt-5'>{`1 Free mint+${parseFloat(0.004*(value-1)).toFixed(3)} eth`}</p> : <p className='text-white mt-5'>Mint price: {`${parseFloat(0.004*value).toFixed(3)} eth`}</p>} */}
        {connect ? <div/> : <div> {<p className='text-black'>Price: {`${parseFloat(0.0069*value).toFixed(4)} eth`}</p>}</div>}
        {totalDisplay && <p className='text-black mt-1'>Supply({`${total}/5000`})</p>}
       
        {/* <h2>NOT LIVE</h2> */}
        <InputRangeSlider bal={bal} setError={setError} value={value} setValue={setValue}/>
        
        {connect ? <ConnectButton setWallets={setWallets} setConnect={setConnect} /> :<><MintButton value={value} setValue={setValue} free={free}/></>}
        </div>
    </div> </>
  )
}

export default Mint1