import React from "react";
import Slider from "../components/Slider";
import "./Mint.css";
import { useState } from "react";
import banner from  '../assets/banner/gifog1.PNG'
import frame from '../assets/background/Frame1.png'
import frame2 from '../assets/background/frame2.png'
import { NavLink } from "react-router-dom";
import blast from "../assets/logo/blast.svg";
import { useNavigate } from "react-router-dom";
function Mint() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  return (
    <div class="containe1">
      <div class="left-col">
        <img className=" cyber-glitch-0" src={banner} alt="" />
        {/* <img className="ml-14 w-52" src={blast} alt="" /> */}

      </div>
      <div class="right-col  flex-col">
        {/* <h1 className="titleLogo  "><span className="cyberpunk-font-og fg-black">PUNkS</span><span className="oxanium-font  text-[50px]">&nbsp;&nbsp;404</span> </h1> */}
        <img  src={frame}/>
       
        <h4 className="pt-14 pb-4 last oxanium-font cyber-glitch-2">
        Punks-404 consists of 10,000 punks in which 5000 are available for public mint, utilizes a modified version of ERC404—an innovative token standard facilitating enduring liquidity and partial fungibility for Ethereum NFTs (ERC20 X ERC721). 
<br></br>
<br></br>
With our latest addition to the erc-404 implementation, traders may now earn $punks for the duration that they hold the tokens.  For more info <a href="https://www.punks404.com/#/about" className="text-[#006ce8]">Click Here</a>
<br></br>

        </h4>
        {/* <NavLink
              exact
              to={"/about"}
              className="bg-white p-7 px-20 text-xl shadow-md shadow-black text-[#191919]  rounded-md flex space-x-2 w-fit px-8 border-[#191919] border-2 p-3 mt-6 h-10 font-normal text-sm leading-3 text-white bg-[#191919] focus:outline-none focus:bg-[#1DA1F2] hover:bg-[#1DA1F2] duration-150 justify-center items-center"
              activeClassName=""
            >
              MINT
            </NavLink> */}
            <button onClick={() => {
          navigate("/mint");
      }} class="cyber-button-small bg-black fg-white">
        
    MINT 
    <span class="glitchtext">punks404</span>
    <span class="tag">404</span>
</button>
    
      </div>
      
    </div>
  );
}

export default Mint;
