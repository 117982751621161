import React from "react";
import Grid from "../components/Grid";
import "./About.css";
import banner from  '../assets/banner/bannerabout.PNG'
function About() {
  return (
    <div className="abt">
      <div class="titleabout text-contain oxanium-font">
        <div className="about">
          <img src={banner} alt="" />
          <br></br>
          
          <br></br>
          <h6 className="pl-10 pr-10">
          Welcome to Punks-404, where innovation meets artistry in the world of NFTs! Our collection of 10,000 unique Punks, inspired by the legendary cryptopunks but with a distinctive twist. We owe a debt of gratitude to @ctrl for his incredible contribution in crafting the innovative ERC-404 standard, which seamlessly blends the liquidity of ERC20 with the uniqueness of ERC721.
          <br />
          <br />
          At Punks-404, we are not just about digital art; we're pioneering a new era of tokenomics designed to reward and empower our community of long-term holders. Our vision is to see this concept embraced across projects, creating a landscape where loyalty is recognized and incentivized. The heart of our reward system lies in the ERC-404 implementation.
          <br />
          <br />
          The mining rate is set to be the highest during the initial week, creating an exciting opportunity for early adopters. As time progresses, the difficulty increases, ensuring that the rewards align with the dedication of our community. Claiming your rewards is a breeze, thanks to our automated system. Whether you're transferring entire tokens or fractional portions as NFTs, your account will be credited with earned $punks automatically.
          </h6>
          <br />
          Our vision at Punks-404 is to revolutionize the NFT landscape by seamlessly integrating art and technology, fostering a community of dedicated holders rewarded for their commitment. Our goal is to contribute to the space, inspiring projects to embrace the ERC-404 implementation creatively, incentivizing long-term engagement and setting a new standard for enduring liquidity in the Ethereum NFT ecosystem. We are not affiliated with Cryptopunks.
        </div>

       
      


        <br></br>
        <br></br>
        {/* <h1>TEAM</h1> */}
      </div>

      {/* <Grid /> */}
    </div>
  );
}

export default About;
