import React, { useEffect, useState } from "react";
import "./MintButton.css";
import { useNavigate } from "react-router-dom";
import { connectWallet } from "../utils/interact";

function ConnectButton({ setWallets, setConnect }) {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [walletError, setWalletError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    addWalletListener(); // Add the wallet listener on component mount
  }, []);

  const connectWalletPressed = async () => {
    connectWallet().then((msg) => {
      if (msg.status !== "Connected") {
        setWalletError(true);
      } else {
        if (msg.address !== "" && msg.status === "Connected") {
          setConnect(false);
        }

        setStatus(msg.status);
        setWallet(msg.address);
        setWallets(msg.address);
      }
    });
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  return (
    <div className="buttons">
      {walletError && (
        <p className="w-[300px] mb-5 text-red-400">
          Something went wrong. Check if you are on the right network
        </p>
      )}
      <p>{status}</p>
      <p>{walletAddress}</p>
      <button
        onClick={connectWalletPressed}
        className="cyber-button-small bg-black mb-6 fg-white"
      >
        Connect
        <span class="glitchtext">punks404</span>
        <span class="tag">404</span>
      </button>
      <br />
    </div>
  );
}

export default ConnectButton;
