import React from "react";
import CardMine from "../components/CardMine";
import InputRangeSlider from "../components/Slider";
import "./Mine2.css";
import { useState, useEffect } from "react";

import ConnectButton from "../components/ConnectButton";
import { useParams } from "react-router-dom";
import { checkSupply, checkStatus, checkRewards } from "../utils/interact";

function Mint2() {
  const [free, setFree] = useState(0);

  const count = checkRewards().then((count) => {
    console.log(count);

    if (count) {
      setFree(count);
    }
  });

  const calculateResult = async () => {
    checkRewards().then((count) => {
      console.log(count);
  
      if (count) {
        setFree(count);
      }
    })
  };

  //  checkStatus().then((msg) => {

  //     setStatus(msg);

  //  })

  useEffect(() => {
    // const count = checkRewards().then((count) => {
    //   console.log(count);

    //   setFree(count);
    // });

    calculateResult();

    // Set up an interval to update the result every 1000 milliseconds (1 second)
    const updateInterval = 1000; // 1 second
    const intervalId = setInterval(calculateResult, updateInterval);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);

  
  }, []);

  const [total, setTotal] = useState();
  const [status, setStatus] = useState(false);
  const [totalDisplay, setTotalDisplay] = useState(false);

  const { id } = useParams();

  console.log(total);

  console.log(id);

  const [connect, setConnect] = useState(true);
  const [value, setValue] = useState(1);

  if (!connect) {
    checkSupply().then((data) => {
      if (data.success != false) {
        setTotalDisplay(true);
        setTotal(data);
      }
    });
  }

  return (
    <>
      <div className="">
        <div className="mobalert">
          <p>Note: Use metamask browser on mobile device. </p>
        </div>
        <div className="">
          <div className="border">
            <div class="boxx">
              {/* <div class="box__line box__line--top"></div>
              <div class="box__line box__line--bottom"></div>
              <div class="box__line box__line--left"></div>
              <div class="box__line box__line--right"></div> */}
              <div class="text-container">
                {connect ? (
                  <div className="buttmob">
                    <ConnectButton setConnect={setConnect} />
                  </div>
                ) : (
                  <>
                    {free ? (
                      <div className="reward">
                        {free}{" "}
                        <span className="font-black text-lg">$PUNKS</span>
                      </div>
                    ) : (
                      <div className="text-black">User not found</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mint2;
