import React, { useState } from "react";
import Socials from "./Socials";
import logo from "../assets/logo/logo.png";
import blast from "../assets/logo/blast2.svg";
import { NavLink } from "react-router-dom";
import './Menu1.css'
import MobileMenu from "./MobileMenu";
export default function Menu1() {
  const [show, setshow] = useState(false);
 
  return (
    <div className=" bg-[]  w">
      <nav className="2xl:container 2xl:mx-auto sm:py-0 sm:px- py-5 ">
        {/* For large and Medium-sized Screen */}
        <div className="flex  justify-between px-5 items-center ">
          <div className=" flex  space-x-3 items-center">
            <div className="flex  items-center">
              <div className="flex">
                
                {/* <h1 className="titleLog cyber-glitch-4"><span className="cyberpunk-font-og text-[50px]">PUNKS</span><span className="text-xll">&nbsp;&nbsp;404</span></h1> */}

                <img className="mush" src={logo} alt="" />
              </div>
            </div>
          </div>

          <div className="hidden pl-0 sm:flex flex-row space-x-16">
            <NavLink
              exact
              to={"/home"}
              className="cyber-button-small bg-yellow fg-black"
              activeClassName="active"
            >
              Home
              <span class="glitchtext">punk404</span>
    <span class="tag">404</span>
            </NavLink> 
            <NavLink
              exact
              to={"/mint"}
              className="cyber-button-small bg-yellow fg-black"
              activeClassName="active"
            >
              MINT
              <span class="glitchtext">punk404</span>
    <span class="tag">404</span>
            </NavLink> 
            <NavLink
              exact
              to={"/mine"}
              className="cyber-button-small bg-yellow fg-black"
              activeClassName="active"
            >
              $REWARDS
              <span class="glitchtext">punk404</span>
    <span class="tag">404</span>
            </NavLink> 
            {/* <NavLink
              exact
              to={"/airdrop"}
              className="cyber-button-small bg-yellow fg-black"
              activeClassName="active"
            >
              AIRDROP
              <span class="glitchtext">punk404</span>
    <span class="tag">404</span>
            </NavLink>  */}
            <NavLink
              exact
              to={"/about"}
              className="cyber-button-small bg-yellow fg-black"
              activeClassName="active"
            >
              ABOUT
              <span class="glitchtext">punk404</span>
    <span class="tag">404</span>
            </NavLink> 
            
            
            
          </div>
            <div className="hidden sm:flex flex-row">
              <img className="mr-10 w-52" src={blast} alt="" />
              <Socials />
            </div>
          {/* Burger Icon */}
          <div
            id="bgIcon"
            onClick={() => setshow(!show)}
            className={`focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  justify-center items-center sm:hidden cursor-pointer`}
          >
            <svg
              className={`${show ? "hidden" : ""}`}
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" transform duration-150"
                d="M4 6H20"
                stroke="#ffffff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 12H20"
                stroke="#ffffff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                className=" transform duration-150"
                d="M4 18H20"
                stroke="#ffffff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <svg
              className={`${show ? "block" : "hidden"}`}
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#ffffff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#ffffff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        {/* Mobile and small-screen devices (toggle Menu) */}
        <MobileMenu setshow={setshow} show={show}/>
      </nav>
    </div>
  );
}
