import React, { useState } from 'react';
import './YourComponent.css'; // Make sure to import your CSS file

const YourComponent = () => {
  const [animationStarted, setAnimationStarted] = useState(false);

  const startAnimation = () => {
    setAnimationStarted(true);
  };

  const resetAnimation = () => {
    setAnimationStarted(false);
    document.dispatchEvent(new Event('animate:reset'));
  };

  return (
    <div> 

      <div className={`congrats ${animationStarted ? 'animation-started' : ''}`}>
        <div className="el ang-a"></div>
        <div className="el ang-b"></div>
        <div className="el bg bg-1"></div>
        <div className="el bg bg-2"></div>
        <div className="el dots">...</div>
        <div className="el glow"></div>
        <div className="el shine"></div>
        <div className="el text">ELIGIBLE</div>
      </div>
    </div>
  );
};

export default YourComponent;
