import React, { useState } from "react";
import './Slider.css'

const InputRangeSlider = ({bal,setError,value,setValue}) => {


const handleChange = (e) => {
setValue(e.target.value);
console.log(e.target.value);
let total = ((e.target.value)* 1e15 )+ (e.target.value*1e16) ;
console.log(total);
bal = parseFloat(bal)
console.log(bal);
if( bal < total){
  setError(false)
} else {
  setError(true);
}
};

return (
<div>
<span id="rangeValue">{value}</span>
<input
     className="range"
     type="range"
     value={value}
     min="1"
     max="20"
     onChange={handleChange}
     onMouseMove={handleChange}
   />
</div>
);
};

export default InputRangeSlider;