import React from "react";
import "./MintButton.css";
import { useEffect,useState } from "react";
import { connectWallet,getCurrentWalletConnected,mintNFT } from "../utils/interact";
function MintButton({value,setValue,free}) {
  
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  useEffect(() => { 
    const {address, status} =   getCurrentWalletConnected();
    setWallet(address)
    setStatus(status); 
    
  }, []);

  const connectWalletPressed = async () => { 
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  return (
    <div className="buttons">
        <p>{status}</p>
        <p>{walletAddress}</p>
      <button onClick={() => {
        console.log("Clicked");
        console.log(value);
        
         mintNFT(value).then((msg) => {
           console.log(msg);
         })
      }} className="cyber-button-small bg-blue mb-6 fg-white ">
        MINT
        <span class="glitchtext">punks404</span>
    <span class="tag">404</span>
      </button>
      <br />

      
    </div>
  );
}

export default MintButton;
