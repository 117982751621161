import React from 'react'
import opensea from "../assets/logo/element.png";
import etherscan from "../assets/logo/etherscan.png";
import twitter from "../assets/logo/twitter.png";
import discord from "../assets/logo/discord.png";

function Socials() {
  return (
    <div className="flex justify-evenly items-center w-28  ">
    <a target="_blank" href="https://element.market/collections/punks404-Official?search%5Btoggles%5D[0]=BUY_NOW">
      <img src={opensea} alt="" />
    </a>
    <a target="_blank" className="mx-4" href="https://twitter.com/Punks_404">
      <img src={twitter} alt="" />
    </a>
    <a target="_blank" href="https://blastscan.io/address/0x887a65e240f8ef5b2e50d806e5b67a6d955cae03">
      <img src={etherscan} alt="" />
    </a>
  </div>
  )
}

export default Socials