import React from "react";
import "./AirConnectButton.css";
import { useNavigate } from "react-router-dom";
import { useEffect,useState } from "react";
import { connectWallet,getCurrentWalletConnected,mintNFT } from "../utils/interact";
function ConnectButton({setConnect,setAdd}) {

  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const navigate = useNavigate();
  const [walletError,setWalletError] = useState(false);
  useEffect(() => { 
    
    
  },[status]);

  const connectWalletPressed = async () => { 
     connectWallet().then((msg) => {
      console.log(msg);
      if(msg.status!="Connected"){
        setWalletError(true);
      }else {
        console.log(msg);
        if(msg.address!="" && msg.status=="Connected"){
            setConnect(false);
        }
       
        setStatus(msg.status);
        setWallet(msg.address);
        setAdd(msg.address)
      }
    })
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }
  

  return (
    <div className="buttons">
      {walletError && <p className="w-[300px] mb-5 text-red-400">Something went wrong. Check if you are on the right network</p>}
        <p>{status}</p>
        <p>{walletAddress}</p>
      <button onClick={connectWalletPressed} className="cyber-button-small bg-black mb-6 fg-white">
        Connect
        <span class="glitchtext">punks404</span>
    <span class="tag">404</span>
      </button>
      <br />

      
    </div>
  );
}

export default ConnectButton;
